import {
    Collapse,
    FormControl,
    Grid,
    makeStyles,
    MenuItem,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Toolbar,
    Typography,
} from "@material-ui/core";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import OfficeTimingRow from "./OfficeTimingRow";
import NewOfficeTiming from "./NewOfficeTiming";
import ArrowDropDownOutlinedIcon from "@material-ui/icons/ArrowDropDownOutlined";
import ArrowDropUpOutlinedIcon from "@material-ui/icons/ArrowDropUpOutlined";
import DeterminateLinearProgress from "../../../utils/DeterminateLinearProgress";
import FormHelperText from "@material-ui/core/FormHelperText";

const useOfficeTimingsStyles = makeStyles((theme) => ({
    disabled: {
        backgroundColor: theme.palette.action.hover,
    },
    root: {
        transition: theme.transitions.create("background-color", {
            duration: "300ms",
        }),
        marginBottom: 15,
    },
    header: {
        cursor: "pointer",
    },
    bottomAddPanel: {
        width: "auto",
    },
    enableSwitch: {
        marginBottom: 15,
        marginLeft: 15,
        marginRight: 15,
    },
    row: {
        paddingLeft: 15,
        paddingRight: 15,
        marginBottom: 10,
        direction: "row",
        justifyContent: "space-between",
        alignItems: "center",
    },
    toolbar: {
        paddingLeft: 15,
        paddingRight: 15,
    },
}));

export default function OfficeTimings(props) {
    const classes = useOfficeTimingsStyles();
    const [openSettings, setOpenSettings] = useState(false);
    const respStatus = props.respStatus;
    const [sendingReq, setSendingReq] = useState(false);
    const [tz, setTz] = React.useState(props.timezone);
    const update = (value, values, timezone) => {
        props.setOpenSnackbar(false);
        setSendingReq(true);
        props.onChange(value, values, timezone);
    };
    const onTzChange = (event) => {
        setTz(event.target.value);
        update(props.enable, props.values, event.target.value);
    };

    useEffect(() => {
        setTz(props.timezone);
    }, [props.timezone]);

    useEffect(() => {
        if (respStatus) {
            setSendingReq(false);
            props.setRespStatus(null);
        }
    }, [respStatus]);

    const updateValue = (value) => {
        const index = props.values.findIndex((i) => i.id === value.id),
            values = [...props.values];
        values[index] = value;
        update(props.enabled, values, props.timezone);
    };
    const addValue = (value) =>
        update(props.enabled, [...props.values, value], props.timezone);
    const deleteValue = (id) =>
        update(
            props.enabled,
            props.values.filter((item) => item.id !== id),
            props.timezone
        );

    const handleOpen = () => {
        setOpenSettings(!openSettings);
    };

    const getSortedItems = () => {
        let itemsWithoutWeekday = Array();
        const weekdays = props.values.filter((item) => {
            if (item.day_display) {
                return item;
            } else {
                if (item.start_time) {
                    itemsWithoutWeekday.push(item);
                }
            }
        });

        weekdays.sort((a, b) => {
            if (a.day_display === b.day_display) {
                return (
                    new Date(a.start_time).getTime() -
                    new Date(b.start_time).getTime()
                );
            }
        });

        const sorted = itemsWithoutWeekday.sort((a, b) => {
            return (
                new Date(a.start_time).getTime() -
                new Date(b.start_time).getTime()
            );
        });
        if (props.holidaysOnly) {
            return [...sorted];
        }
        return [...weekdays, ...sorted];
    };

    return (
        <Paper
            className={clsx(classes.root, !openSettings && classes.disabled)}
        >
            <DeterminateLinearProgress
                visible={sendingReq}
                opened={openSettings}
            />
            <Toolbar className={classes.toolbar} onClick={handleOpen}>
                <Grid
                    className={classes.header}
                    container
                    direction="row"
                    justify="space-between"
                    component="label"
                >
                    <Grid item>
                        <Typography variant="h6" component="div">
                            {" "}
                            Response Time
                        </Typography>
                    </Grid>
                    <Grid item>
                        {!openSettings ? (
                            <ArrowDropDownOutlinedIcon
                                fontVariant="outlined"
                                color="primary"
                                fontSize="large"
                                onClick={handleOpen}
                            />
                        ) : (
                            <ArrowDropUpOutlinedIcon
                                fontVariant="outlined"
                                color="primary"
                                fontSize="large"
                                onClick={handleOpen}
                            />
                        )}
                    </Grid>
                </Grid>
            </Toolbar>
            <Collapse in={openSettings}>
                <Grid container className={classes.row} spacing={3}>
                    <Grid item xs={6}>
                        <Typography>Select Your Timezone</Typography>
                    </Grid>
                    <Grid item xs={6} style={{ textAlign: "end" }}>
                        <FormControl className={classes.formControl}>
                            <Select
                                labelId="holidays-duration-select-helper-label"
                                id="holidays-duration-select-helper"
                                value={tz}
                                onChange={onTzChange}
                            >
                                <MenuItem value="America/New_York">EST/EDT</MenuItem>
                                <MenuItem value="CST">CST</MenuItem>
                                <MenuItem value="MST">MST</MenuItem>
                                <MenuItem value="PST">PST</MenuItem>
                                <MenuItem value="AKST">AKST</MenuItem>
                                <MenuItem value="HST">HST</MenuItem>
                            </Select>
                            <FormHelperText>Pick Timezone</FormHelperText>
                        </FormControl>
                    </Grid>
                </Grid>
                <TableContainer>
                    <Table
                        className={classes.table}
                        size="small"
                        aria-label="simple table"
                    >
                        <TableHead>
                            <TableRow>
                                <TableCell padding="checkbox">
                                    Enabled
                                </TableCell>
                                <TableCell>Day</TableCell>
                                <TableCell padding="checkbox">
                                    All Day
                                </TableCell>
                                <TableCell>Start Time</TableCell>
                                <TableCell>End Time</TableCell>
                                <TableCell />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {getSortedItems().map((item) => (
                                <OfficeTimingRow
                                    key={item.id}
                                    enabled={props.enabled}
                                    onChange={updateValue}
                                    onDelete={deleteValue}
                                    {...item}
                                />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <NewOfficeTiming
                    addOT={addValue}
                    holidaysOnly={props.holidaysOnly}
                />
            </Collapse>
        </Paper>
    );
}
